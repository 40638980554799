import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Tab,
  Tabs
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from '../Page';
import LoadingScreen from '../LoadingScreen';
import StaffLoginForm from './StaffLoginForm';
import PatientProfileLoginForm from './PatientProfileLoginForm';
import {StaffProfileType} from "@veris-health/user-ms/lib/v1";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function LoginView() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitStart = () => {
    setIsLoading(true);
  };

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page className={classes.root} title="Veris Health API Docs">
      <Container maxWidth="sm">
        <Card className={classes.card} elevation={5}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              Log In
            </Typography>
            <Box my={2} />
            <Typography variant="subtitle1" color="textSecondary">
              API Docs Log In.
            </Typography>
            <Box my={2} />
            <Box>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor='primary'
                  variant='fullWidth'
                >
                  <Tab label="Medical Staff" />
                  <Tab label="Tech Staff" />
                  <Tab label="Patient Profile" />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box mt={2}>
                  <StaffLoginForm onSubmitStart={handleSubmitStart} />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box mt={2}>
                  <StaffLoginForm onSubmitStart={handleSubmitStart} type={StaffProfileType.TechStaff} />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box mt={2}>
                  <PatientProfileLoginForm onSubmitStart={handleSubmitStart} />
                </Box>
              </TabPanel>
            </Box>
            <Box my={2} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
