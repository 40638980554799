import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import config from '../config';
import authService from '../services/authService';
import FormDialog from './FormDialog';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import DisableAuthorizePlugin from './swaggerPlugins/DisableAuthorize';
import { getApiBaseUrl } from '../services/utils/environment';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: 99999,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    top: 64
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  logOutButton: {
    float: 'right',
    padding: 0,
    color: 'white'
  }
}));

const requestInterceptor = (request) => {
  // No cache
  request.headers['Cache-Control'] = 'no-cache';
  request.headers.Pragma = 'no-cache';
  request.headers.expires = 0;

  let bearerToken = localStorage.getItem('docs-dynamic-accessToken');
  if (request.url.match(/.*\/openapi.(yaml|yml|json)/g)) {
    bearerToken = localStorage.getItem('docs-accessToken');
  }
  if (!bearerToken) {
    console.warn('There is no bearer token on local store');
    return request;
  } else {
    request.headers.Authorization = `Bearer ${bearerToken}`;
    return request;
  }
};

export default function MainView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedNavItem, setSelectedNavItem] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [version, setVersion] = useState(config[0].versions[0]);

  // Default to dev
  const hostName = getApiBaseUrl();
  const selectedService = config[selectedNavItem];

  const logOut = () => {
    authService.logout();
    window.location.reload();
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onConfirmDialog = (dynamicToken) => {
    try {
      localStorage.setItem('docs-dynamic-accessToken', dynamicToken);
      enqueueSnackbar('Token updated successfully', {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  const onResetToken = () => {
    try {
      localStorage.setItem('docs-dynamic-accessToken', '');
      enqueueSnackbar('Token reset successfully', {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  const getVersionLabel = (version) => {
    if (!version) {
      return 'None';
    }
    return version.toUpperCase();
  };

  const getVersionPath = (version) => {
    if (!version) {
      return '';
    }
    return `/${version}`;
  };

  const onSelectService = (index) => {
    try {
      if (
        authService.isValidToken(authService.getAccessToken()) &&
        authService.isAuthenticated()
      ) {
        setSelectedNavItem(index);
        setVersion(config[index].versions[0]);
      } else {
        logOut();
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  return (
    <div className={classes.root}>
      {openDialog && (
        <FormDialog
          open={openDialog}
          initValue={localStorage.getItem('docs-dynamic-accessToken')}
          onCloseDialog={onCloseDialog}
          onConfirmDialog={onConfirmDialog}
        />
      )}
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" noWrap>
                Veris Health API Docs
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.logOutButton}
                onClick={() => {
                  logOut();
                }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <List>
          <ListItem>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Set Token
            </Button>
          </ListItem>
          <ListItem>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                onResetToken();
              }}
            >
              Reset Token
            </Button>
          </ListItem>
          <ListItem>
            <Box sx={{ width: 'inherit', mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>Version</InputLabel>
                <Select
                  displayEmpty
                  value={version}
                  label="version"
                  onChange={(event) => {
                    setVersion(event.target.value);
                  }}
                >
                  {selectedService.versions.map((v) => (
                    <MenuItem key={v} value={v}>
                      {getVersionLabel(v)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </ListItem>
          <Box mb={2}>
            <Divider style={{ height: 2 }} variant="fullWidth" />
          </Box>
          {config.map((service, index) => (
            <ListItem
              button
              key={`${service.service_name}-${version}`}
              selected={index === selectedNavItem}
              onClick={() => {
                onSelectService(index);
              }}
            >
              <ListItemText primary={`${service.label}`} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <SwaggerUI
          requestInterceptor={requestInterceptor}
          plugins={[DisableAuthorizePlugin]}
          url={`${hostName}/${selectedService.service_name}${getVersionPath(
            version
          )}/openapi.json`}
        />
      </main>
    </div>
  );
}
