import React, {useCallback, useEffect, useRef, useState} from 'react';
import { SnackbarProvider } from 'notistack';
import LoginView from './components/LoginView';
import LoadingScreen from './components/LoadingScreen';
import authService from './services/authService';
import MainView from './components/MainView';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const timeoutRef = useRef(null);

  const destroyTimeout = ()=> {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const createTimeout = useCallback(() => {
    destroyTimeout();
    timeoutRef.current = setTimeout(() => {
      authService.logout();
      window.location.reload();
    }, 15 * 60 * 1000);
  },[])
  
  const checkIfAuthenticated = () => {
    setIsAuthenticated(
      authService.isValidToken(authService.getAccessToken()) &&
      authService.isAuthenticated()
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      createTimeout();
      document.addEventListener("click", createTimeout);
    }

    return () => {
      document.removeEventListener("click", createTimeout);
      destroyTimeout();
    };
  }, [createTimeout, isAuthenticated]);

  useEffect(() => {
    checkIfAuthenticated();

    // check token every 3 seconds
    const intervalId = setInterval(() => {
      checkIfAuthenticated();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (isAuthenticated === null) {
    return <LoadingScreen />;
  }

  return (
    <SnackbarProvider maxSnack={1}>
      {isAuthenticated && <MainView />}
      {!isAuthenticated && <LoginView />}
    </SnackbarProvider>
  );
}
