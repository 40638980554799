import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import AuthService from '../../services/authService';
import ButtonCircularProgress from '../ButtonCircularProgress';

const useStyles = makeStyles(() => ({
  root: {}
}));

const deviceIdRegExp = /^.*$/;
const pinRegExp = /^.*$/;

function PatientProfileLoginForm({ className, onSubmitStart, ...rest }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        deviceId: '',
        pin: ''
      }}
      validationSchema={Yup.object().shape({
        deviceId: Yup.string()
          .matches(
            deviceIdRegExp,
            'Device id address not valid.'
          )
          .required('Device id address is required'),
        pin:
          Yup.string()
            .matches(pinRegExp, 'Pin is not valid')
            .required('Pin is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          await AuthService.userLogin(values.deviceId.toString(), values.pin.toString());
          onSubmitStart();
        } catch (error) {
          console.log(error)
          const message = error.response.data.message || 'Something went wrong';
          setStatus({ success: false });
          setErrors({ submit: message });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box mt={1}>
            <TextField
              error={Boolean(touched.deviceId && errors.deviceId)}
              fullWidth
              autoFocus
              helperText={touched.deviceId && errors.deviceId}
              label="Device Id"
              margin="normal"
              name="deviceId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.deviceId}
              placeholder={'e.g. 000000'}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.pin && errors.pin)}
              fullWidth
              helperText={touched.pin && errors.pin}
              label="Pin"
              margin="normal"
              name="pin"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pin}
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={false}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {isSubmitting && <ButtonCircularProgress white marginRight={10} />}
              {'Login'}
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

PatientProfileLoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitStart: PropTypes.func
};

PatientProfileLoginForm.defaultProps = {
  onSubmitStart: () => { }
};

export default PatientProfileLoginForm;
