import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import AuthService from '../../services/authService';
import ButtonCircularProgress from '../ButtonCircularProgress';

const useStyles = makeStyles(() => ({
  root: {}
}));

const emailRegExp = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const passwordRegExp = /^.*$/;

function StaffLoginForm({ className, onSubmitStart,type, ...rest }) {
  const classes = useStyles();

  const [isRequestingOtp, setIsRequestingOtp] = useState(true);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        otp: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .matches(
            emailRegExp,
            'Email address not valid.'
          )
          .required('Email address is required'),
        password:
          Yup.string()
            .matches(passwordRegExp, 'Password is not valid')
            .required('Password is required'),
        otp: !isRequestingOtp && Yup.string().required('OTP is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          if (isRequestingOtp) {
            await AuthService.staffUserLoginOtp(values.email, values.password, type);
            setIsRequestingOtp(false);
          } else {
            await AuthService.staffUserLogin(values.email, values.password, values.otp, type);
            onSubmitStart();
          }
        } catch (error) {
          const message = error.response.data.message || 'Something went wrong';
          setStatus({ success: false });
          setErrors({ submit: message });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box mt={1}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              autoFocus
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              placeholder={'e.g. example@domain.com'}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {!isRequestingOtp && (
              <TextField
                error={Boolean(touched.otp && errors.otp)}
                fullWidth
                helperText={touched.otp && errors.otp}
                label="OTP"
                margin="normal"
                name="otp"
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                value={values.otp}
                variant="outlined"
              />
            )}
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={false}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {isSubmitting && <ButtonCircularProgress white marginRight={10} />}
              {isRequestingOtp ? 'Request OTP' : 'Login'}
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

StaffLoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitStart: PropTypes.func
};

StaffLoginForm.defaultProps = {
  onSubmitStart: () => { }
};

export default StaffLoginForm;
