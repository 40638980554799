import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

function ButtonCircularProgress({ style, white, marginRight, marginLeft }) {
  let styleObj = {
    marginRight,
    marginLeft
  };
  if (white) {
    styleObj = { ...styleObj, color: '#ffffff' };
  }
  styleObj = {
    ...styleObj,
    ...style
  };
  return <CircularProgress style={styleObj} size={20} thickness={5} />;
}

ButtonCircularProgress.propTypes = {
  style: PropTypes.object,
  white: PropTypes.bool,
  marginRight: PropTypes.any,
  marginLeft: PropTypes.any
};

export default ButtonCircularProgress;
