const config = [
  {
    label: 'User',
    service_name: 'user',
    versions: ["v1", "v2"]
  },
  {
    label: 'Virtual Doc',
    service_name: 'virtual-doc',
    versions: ["v1"]
  },
  {
    label: 'Med Data',
    service_name: 'med-data',
    versions: ["v1"]
  },
  {
    label: 'Communications',
    service_name: 'communication',
    versions: ["v1"]
  },
];

export default config;
