import jwtDecode from 'jwt-decode';
import {
  LoginMedicalStaffApi as LoginMedicalStaffApiV1,
  LoginApi as LoginApiV1,
  StaffProfileType
} from '@veris-health/user-ms/lib/v1';
import { getApiBaseUrl } from './utils/environment';

class AuthService {
  constructor() {
    this.loginMedicalStaffApi = new LoginMedicalStaffApiV1();
    this.loginApi = new LoginApiV1();

    this.loginMedicalStaffApi.basePath = getApiBaseUrl() + '\\user/v1';
    this.loginApi.basePath = getApiBaseUrl() + '\\user/v1';
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  staffUserLogin = (email, password, otp, type = StaffProfileType.MedStaff) =>
    new Promise((resolve, reject) => {
      this.loginMedicalStaffApi
        .staffUserLogin({
          type,
          email,
          password,
          otp
        })
        .then((response) => {
          if (response.error) {
            reject(response.error);
          } else if (response.data.accessToken) {
            this.setSession(response.data.accessToken);
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  userLogin = (mobileDeviceId, secret) =>
    new Promise((resolve, reject) => {
      this.loginApi
        .userLogin({
          mobileDeviceId,
          secret,
          authType: 'PIN'
        })
        .then((response) => {
          if (response.error) {
            reject(response.error);
          } else if (response.data.accessToken) {
            this.setSession(response.data.accessToken);
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  staffUserLoginOtp = (email, password, type = StaffProfileType.MedStaff) =>
    new Promise((resolve, reject) => {
      this.loginMedicalStaffApi
        .staffUserLoginOtp({
          type,
          email,
          password
        })
        .then((response) => {
          if (response.error) {
            reject(response.error);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('docs-accessToken', accessToken);
      localStorage.setItem('docs-dynamic-accessToken', accessToken);
    } else {
      localStorage.removeItem('docs-accessToken');
      localStorage.removeItem('docs-dynamic-accessToken');
    }
  };

  getAccessToken = () => localStorage.getItem('docs-accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
