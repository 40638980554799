import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({
  open,
  onCloseDialog,
  onConfirmDialog,
  initValue
}) {
  const [dynamicToken, setSynamicToken] = useState(initValue);

  const handleClose = () => {
    onCloseDialog();
    setSynamicToken(null);
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add the token without "Bearer" prefix.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            value={dynamicToken}
            defaultValue={initValue}
            margin="dense"
            label="Token"
            onChange={(e) => {
              setSynamicToken(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!dynamicToken}
            onClick={() => {
              handleClose();
              onConfirmDialog(dynamicToken);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
